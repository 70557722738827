import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import Captcha from '../Captcha'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'

function Register() {
  const [pesan, setPesan] = useState(false)
  const [data, setData] = useState(1)
  const [profil, setProfil] = useState(0)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [nik, setNIK] = useState('')
  const [nm_lengkap, setNmLengkap] = useState('')
  const [no_hp, setNoHp] = useState('')
  const [nm_badan_usaha, setNmBadanUsaha] = useState('')
  const [jns_badan, setJnsBadan] = useState('')
  const [no_ahu, setNoAhu] = useState('')
  const [nm_notaris, setNmNotaris] = useState('')
  const [no_akta, setNoAkta] = useState('')
  const [no_npwp, setNoNpwp] = useState('')
  const [no_prop_perusahaan, setNoPropPerusahaan] = useState('')
  const [no_kab_perusahaan, setNoKabPerusahaan] = useState('')
  const [no_kec_perusahaan, setNoKecPerusahaan] = useState('')
  const [no_kel_perusahaan, setNoKelPerusahaan] = useState('')
  const [no_rw_perusahaan, setNoRwPerusahaan] = useState('')
  const [no_rt_perusahaan, setNoRtPerusahaan] = useState('')
  const [alamat_perusahaan, setAlamatPerusahaan] = useState('')
  const [kd_pos_perusahaan, setKdPosPerusahaan] = useState('')
  const [email_perusahaan, setEmailPerusahaan] = useState('')
  const [no_telp_perusahaan, setNoTelpPerusahaan] = useState('')
  const [jabatan, setJabatan] = useState('')
  const [jenisUsaha, setJenisUsaha] = useState([])
  const [propinsi, setPropinsi] = useState([])
  const [kabupaten_perusahaan, setKabupatenPerusahaan] = useState([])
  const [kecamatan_perusahaan, setKecamatanPerusahaan] = useState([])
  const [kelurahan_perusahaan, setKelurahanPerusahaan] = useState([])
  const [captcha, setCaptcha] = useState("")
  const [recaptcha, setReCaptcha] = useState("")
  const [type, setType] = useState('password')
  const [msg, setMsg] = useState('')
  const navigate = useNavigate()
  const alert = {
    position: "relative",
    top: "7px",
    backgroundColor: "#e5df7c",
    color: "#333",
  }

  useEffect(() => {
    getPropinsi()
    getJenisUsaha()
    loadCaptchaEnginge(6)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPropinsi = async (filter) => {
    const response = await axios.post('/api/wilayah/propinsi', {
      nama_prop: filter
    })
    setPropinsi(response.data)
  }

  const getJenisUsaha = async () => {
    const response = await axios.get('/api/jenis-usaha')
    setJenisUsaha(response.data)
  }

  const getKabupatenPerusahaan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kabupaten`, {
        no_prop: key
      })
      setKabupatenPerusahaan(response.data)
      setNoKabPerusahaan('')
      setNoPropPerusahaan(key)
    } catch {
      setKabupatenPerusahaan([])
      setNoKabPerusahaan("")
      setNoPropPerusahaan(key)
    }
  }

  const getKecamatanPerusahaan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kecamatan`, {
        no_prop: no_prop_perusahaan,
        no_kab: key
      })
      setKecamatanPerusahaan(response.data)
      setNoKecPerusahaan('')
      setNoKabPerusahaan(key)
    } catch {
      setKecamatanPerusahaan([])
      setNoKecPerusahaan("")
      setNoKabPerusahaan(key)
    }
  }

  const getKelurahanPerusahaan = async (key) => {
    try {
      const response = await axios.post(`/api/wilayah/kelurahan`, {
        no_prop: no_prop_perusahaan,
        no_kab: no_kab_perusahaan,
        no_kec: key
      })
      setKelurahanPerusahaan(response.data)
      setNoKelPerusahaan('')
      setNoKecPerusahaan(key)
    } catch {
      setKelurahanPerusahaan([])
      setNoKelPerusahaan("")
      setNoKecPerusahaan(key)
    }
  }

  const typePassword = () => {
    if (type === "password") {
      setType('text')
    } else {
      setType('password')
    }
  }

  const getData = (e) => {
    e.preventDefault()
    setProfil(0)
    setData(1)
  }

  const getProfil = async (e) => {
    e.preventDefault()
    if (nik.length !== 16) {
      getPesan()
      setMsg('NIK 16 harus digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (nm_lengkap === '') {
      getPesan()
      setMsg('Harap masukan nama lengkap!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (username === '') {
      getPesan()
      setMsg('Harap masukan username!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (email === '') {
      getPesan()
      setMsg('Harap masukan email!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (password.length < 8) {
      getPesan()
      setMsg('Password min 8 digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (no_hp.length < 10) {
      getPesan()
      setMsg('No hp min 10 digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else if (no_hp.length > 17) {
      getPesan()
      setMsg('No hp max 17 digit!')
      setTimeout(() => {
        getPesan()
        setMsg('')
      }, 2000)
    } else {
      try {
        await axios.post("/api/pelaku-usaha/validasi", {
          nik,
          username,
          email,
          password,
          no_hp
        })
        setProfil(1)
        setData(0)
      } catch (err) {
        setProfil(0)
        setData(1)
        getPesan()
        setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
        setTimeout(() => {
          getPesan()
          setMsg("")
        }, 2000)
      }
    }
  }

  const getPesan = () => {
    setPesan((prevState) => !prevState)
  }

  const display = {
    display: `${pesan ? "block" : "none"}`,
  }

  const proses = async (e) => {
    e.preventDefault()
    if (validateCaptcha(captcha) === false) {
      setReCaptcha("Captcha tidak sesuai!")
      setTimeout(() => {
        setReCaptcha("")
      }, 2000)
      setCaptcha("")
    } else {
      try {
        const simpan = await axios.post(
          "/api/pelaku-usaha",
          {
            username,
            email,
            password,
            nik,
            nm_lengkap,
            no_hp,
            jenis: 2,
            i_user: username,
            i_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
            u_user: username,
            u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
          }
        )
        perusahaan(simpan.data.token)
        // sendEmail(simpan.data.token, simpan.data.code)
        getPesan()
        setMsg(simpan.data.msg)
        setTimeout(() => {
          getPesan()
          setMsg("")
          navigate('/login')
        }, 1000)
      } catch (err) {
        getPesan()
        setMsg(err.response.data.msg ? err.response.data.msg : 'Maaf, proses pengiriman data gagal silahkan coba lagi!')
        setTimeout(() => {
          getPesan()
          setMsg("")
        }, 2000)
      }
    }
  }

  const perusahaan = async (token) => {
    await axios.post("/api/badan-usaha",
      {
        token: token,
        nm_badan_usaha,
        jns_badan,
        no_ahu,
        nm_notaris,
        no_akta,
        no_npwp,
        email: email_perusahaan,
        no_telp: no_telp_perusahaan,
        no_prop: no_prop_perusahaan,
        no_kab: no_kab_perusahaan,
        no_kec: no_kec_perusahaan,
        no_kel: no_kel_perusahaan,
        no_rw: no_rw_perusahaan,
        no_rt: no_rt_perusahaan,
        alamat: alamat_perusahaan,
        kd_pos: kd_pos_perusahaan,
        penanggung: nm_lengkap,
        jabatan,
        i_user: token,
        i_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
        u_user: token,
        u_date: new Date().toLocaleDateString('pt-br').split('/').reverse().join('-') + " " + new Date().toLocaleTimeString('pt-br'),
      }
    )
  }

  // const sendEmail = async (token, code) => {
  //   await axios.post("/api/pelaku-usaha/email-register",
  //     {
  //       token: token,
  //       code: code,
  //       email
  //     }
  //   )
  // }

  return (
    <>
      <div className="bg-full-page bg-primary back-fixed">
        <div className="mw-800 absolute-center">
          <div className="card color-dark animated zoomInDown animation-delay-5 border-radius-20">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-8 col-6">
                  <h3 className="color-success">Pendaftaran Akun</h3>
                </div>
                <div className="col-md-4 col-6">
                  <img width="100%" src="assets/img/logo-head.png" alt="" />
                </div>
                <div className="col-md-12">
                  <img width="100%" className="line-process" src="assets/img/line.png" alt="" />
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className={(data === 1 | profil === 1) ? 'btn-circle btn-circle-raised btn-circle-success' : 'btn-circle btn-circle-raised btn-circle-default'}>1</button>
                  <br></br>Jenis Usaha
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className={(data === 1 | profil === 1) ? 'btn-circle btn-circle-raised btn-circle-success' : 'btn-circle btn-circle-raised btn-circle-default'}>2</button>
                  <br></br>Data Pengguna Aplikasi
                </div>
                <div className="col-md-4 col-4 text-center">
                  <button className={profil === 1 ? 'btn-circle btn-circle-raised btn-circle-success' : 'btn-circle btn-circle-raised btn-circle-default'}>3</button>
                  <br></br>Profil Pelaku Usaha
                </div>
                <div className="col-md-12 col-12 text-center">
                  <span className="badge badge-danger">Pastikan menggunakan email aktif ketika mendaftarkan akun (<b>Link Aktivasi Akun kami kirim melalui email</b>)</span><br></br>
                </div>
              </div>
              <div className={data === 1 ? 'd-block' : 'd-none'}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nik">NIK</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                      <input type="text" id="nik" className="form-control" value={nik} onChange={(e) => setNIK(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nm_lengkap">Nama Lengkap</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-address-card"></i></span>
                      <input type="text" id="nm_lengkap" className="form-control" value={nm_lengkap} onChange={(e) => setNmLengkap(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="username-login">Nama Pengguna (Username)</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-account"></i></span>
                      <input type="text" id="username-login" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="email-login">Email</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                      <input type="text" id="email-login" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="password-login">Kata Kunci (Password)</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                      <input type={type} id="password-login" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                      <span className="input-group-addon">
                        <span onClick={typePassword}><i className="control-label zmdi zmdi-eye"></i></span>
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nm_lengkap">No. HP</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                      <input type="text" id="no_hp" className="form-control" value={no_hp} onChange={(e) => setNoHp(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={(profil === 1) ? 'd-block' : 'd-none'}>
                <h5 className="right-line color-success">Data Perusahaan</h5>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label className="control-label" htmlFor="nm_badan_usaha">Bertindak Atas Nama / Kuasa / Kerja Sama dengan Perusahaan (Nama Badan Usaha) </label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-building"></i></span>
                      <input type="text" id="nm_badan_usaha" className="form-control" value={nm_badan_usaha} onChange={(e) => setNmBadanUsaha(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Jenis Badan Usaha</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <select className="form-control" value={jns_badan} onChange={(e) => setJnsBadan(e.target.value)} required>
                        <option value="">==</option>
                        {jenisUsaha.map((val, i) => (
                          <option key={i} value={val._id}>{i + 1 + '. ' + val.nm_jenis}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="no_ahu">No. AHU</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <input type="text" id="no_ahu" className="form-control" value={no_ahu} onChange={(e) => setNoAhu(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="nm_notaris">Nama Notaris</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <input type="text" id="nm_notaris" className="form-control" value={nm_notaris} onChange={(e) => setNmNotaris(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="no_akta">No. Akta Pendirian/Perubahan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <input type="text" id="no_akta" className="form-control" value={no_akta} onChange={(e) => setNoAkta(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="no_npwp">No. NPWP Perusahaan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <input type="text" id="no_npwp" className="form-control" value={no_npwp} onChange={(e) => setNoNpwp(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="email_perusahaan">Email Perusahaan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <input type="text" id="email_perusahaan" className="form-control" value={email_perusahaan} onChange={(e) => setEmailPerusahaan(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="no_telp_perusahaan">No. Telpon Perusahaan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-drivers-license"></i></span>
                      <input type="text" id="no_telp_perusahaan" className="form-control" value={no_telp_perusahaan} onChange={(e) => setNoTelpPerusahaan(e.target.value)} />
                    </div>
                  </div>
                </div>
                <h5 className="right-line color-success">Alamat Perusahaan</h5>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label">Propinsi</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_prop_perusahaan} onChange={(e) => getKabupatenPerusahaan(e.target.value)} required>
                        <option value="">==</option>
                        {propinsi.map((val, i) => (
                          <option key={i} value={val.no_prop}>{i + 1 + '. ' + val.nama_prop}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kabupaten</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kab_perusahaan} onChange={(e) => getKecamatanPerusahaan(e.target.value)} required>
                        <option value="">==</option>
                        {kabupaten_perusahaan.map((val, i) => (
                          <option key={i} value={val.no_kab}>{i + 1 + '. ' + val.nama_kab}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kecamatan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kec_perusahaan} onChange={(e) => getKelurahanPerusahaan(e.target.value)} required>
                        <option value="">==</option>
                        {kecamatan_perusahaan.map((val, i) => (
                          <option key={i} value={val.no_kec}>{i + 1 + '. ' + val.nama_kec}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label">Kelurahan</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <select className="form-control" value={no_kel_perusahaan} onChange={(e) => setNoKelPerusahaan(e.target.value)} required>
                        <option value="">==</option>
                        {kelurahan_perusahaan.map((val, i) => (
                          <option key={i} value={val.no_kel}>{i + 1 + '. ' + val.nama_kel}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="alamat_perusahaan">Alamat</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="alamat_perusahaan" className="form-control" value={alamat_perusahaan} onChange={(e) => setAlamatPerusahaan(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="no_rw_perusahaan">No. Rw</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="no_rw_perusahaan" className="form-control" value={no_rw_perusahaan} onChange={(e) => setNoRwPerusahaan(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="no_rt_perusahaan">No. Rt</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="no_rt_perusahaan" className="form-control" value={no_rt_perusahaan} onChange={(e) => setNoRtPerusahaan(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="control-label" htmlFor="kd_pos_perusahaan">Kode Pos</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-map"></i></span>
                      <input type="text" id="kd_pos_perusahaan" className="form-control" value={kd_pos_perusahaan} onChange={(e) => setKdPosPerusahaan(e.target.value)} />
                    </div>
                  </div>
                </div>
                <h5 className="right-line color-success">Data Penanggung Jawab</h5>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="control-label">Nama Penanggung Jawab</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                      <input type="text" className="form-control" value={nm_lengkap} readOnly />
                      {/* <input type="text" id="penanggung" className="form-control" value={penanggung} onChange={(e) => setPenanggung(e.target.value)} /> */}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="control-label" htmlFor="jabatan">Jabatan Penanggung Jawab</label>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-id-badge"></i></span>
                      <input type="text" id="jabatan" className="form-control" value={jabatan} onChange={(e) => setJabatan(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={(profil === 1) ? 'd-block' : 'd-none'}>
                <h5 className="right-line color-success">Captcha</h5>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="text-center">
                      <Captcha />
                    </div>
                    <label className="control-label">Captcha</label>
                    <p style={alert} className="text-center">
                      {recaptcha}
                    </p>
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-lock"></i></span>
                      <input className="form-control" type="text" value={captcha} placeholder="Masukan Captcha *" onChange={(e) => setCaptcha(e.target.value)} required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className={profil === 0 ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <Link to={'/register'} className="btn btn-raised btn-default btn-block p-1 br-2"><i className="zmdi zmdi-long-arrow-left no-ml mr-1"></i>KEMBALI</Link>
                </div>
                <div className={profil === 0 ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <button onClick={getProfil} className="btn btn-raised btn-primary btn-block p-1 br-2">SELANJUTNYA<i className="zmdi zmdi-long-arrow-right no-mr ml-1"></i></button>
                </div>
                <div className={profil === 1 ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <button onClick={getData} className="btn btn-raised btn-default btn-block p-1 br-2"><i className="zmdi zmdi-long-arrow-left no-ml mr-1"></i>KEMBALI</button>
                </div>
                <div className={(profil === 1) ? 'col-md-6 col-6 d-block' : 'col-md-6 col-6 d-none'}>
                  <button onClick={proses} className="btn btn-raised btn-primary btn-block p-1 br-2">DAFTAR<i className="fa fa-vcard no-mr ml-1"></i></button>
                </div>
                <div className="col-md-12 text-center mt-2">
                  Sudah punya akun? <Link className="color-success" to={'/login'}>Masuk</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={pesan ? "modal-open" : ""}>
        <div style={display} className={pesan ? "modal modal-primary show" : "modal modal-primary"} id="myData" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog center">
            <div className="modal-content modal-sm">
              <div className="modal-header header-modal-primary p-1">
                <h4 className="modal-title text-center no-p font-bold" id="myModalLabel">
                  PESAN
                </h4>
              </div>
              <div className="modal-body text-center">
                <h4 className="font-bold">{msg.toUpperCase()}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pesan ? <div className="modal-backdrop fade in"></div> : ""}
    </>
  )
}

export default Register